import React from "react";

// Call Components
import ArticleCard from "../Components/Others/Cards/ArticleCard";
import Breadcrumb from "../Components/Others/Breadcrumbs/Breadcrumb";

const VideoRss = () => {
  return (
    <>
      <Breadcrumb title="Video Resources" para="Watch latest videos for free" />

      <section className="container-fluid spc-y-2">
        <div className="container">
          <div className="grid-row-auto-A pb-20">
            <ArticleCard />
            <ArticleCard />
            <ArticleCard />
            <ArticleCard />
            <ArticleCard />
          </div>
        </div>
      </section>

      <Breadcrumb
        title=" Featured Playlists"
        para="Watch latest videos for free"
      />

      <section className="container-fluid spc-y-2">
        <div className="container">
          <div className="grid-row-auto-A pb-20">
            <ArticleCard />
            <ArticleCard />
            <ArticleCard />
            <ArticleCard />
            <ArticleCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoRss;
