import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosSearch, IoMdClose } from "react-icons/io";

// Call CSS Files
import "../../style/Nav.css";

// Import Images
import profile from "../../Images/design.png";

// Call Components
import NavLinks from "./NavLinks";
import Brand from "./Brand";
import ExploreBtn from "./ExploreBtn";

const Nav = () => {
  const [showSidebar, setshowSidebar] = useState(false);
  const [showSearchdrop, setshowSearchdrop] = useState(false);

  // Toggle Function to show / hide Nav on Mob
  const ToglerFun = () => {
    setshowSidebar(!showSidebar);
  };
  const SrcDropToglerFun = () => {
    setshowSearchdrop(!showSearchdrop);
  };

  return (
    <>
      <nav className="container">
        {/* SEC-1 */}
        <div className="sec-1">
          <Brand />
        </div>

        {/* SEC-2 */}
        <div className={showSidebar ? "sec-2 active" : "sec-2"}>
          {/* ==> Mob View Side Nav Top Sec */}
          <div className="top-logo-close-col">
            <Brand />
            {/* close btn */}
            <button className="close-btn" onClick={ToglerFun}>
              <IoMdClose />
            </button>
          </div>

          <NavLinks />

          {/* ==> Mob View Side Nav ExploreBtn Sec */}
          <div className="side-nav-sm-end">
            <ExploreBtn />
          </div>
        </div>

        {/* SEc-3 */}
        <div className="sec-3">
          {/* Search btn */}
          <button className="prim-lite search-btn" onClick={SrcDropToglerFun}>
            <IoIosSearch />

            {/* search brop content */}
            <div
              className={showSearchdrop ? "dropdown-box show" : "dropdown-box"}
            >
              {/* close btn */}
              <button className="close-btn">
                <IoMdClose />
              </button>

              {/* Nav items */}
              <div className="search-content-box">
                <Link className="nav-link">
                  <img
                    src={profile}
                    alt="icon"
                    className="img-circle-50 shrink-0"
                  />
                  <h5>polkadot</h5>
                </Link>
              </div>
            </div>
          </button>

          {/* Login / Explore all projects btn */}
          <ExploreBtn />

          {/* Toggle btn */}
          <button className="toggle-btn" onClick={ToglerFun}>
            <i class="fa-solid fa-bars-staggered"></i>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Nav;
