import React from "react";

// Call CSS
import "../style/ProElements.css";

// Call Components
import ProjectBreadcrumb from "../Components/Others/Breadcrumbs/ProjectBreadcrumb";
import TabPannelPrim from "../Components/Others/TabPanelComps/TabPanelPrim";
import ParachainsPallets from "../Components/Parachains/ParachainsPallets";
// import ParachainsProjects from "../Components/ParachainsProjects";
// import ParachainsAbout from "../Components/ParachainsAbout";

const ViewParachains = () => {
  return (
    <>
      <ProjectBreadcrumb />

      <section className="container-fluid spc-y-2">
        <div className="container">
          <TabPannelPrim />
          {/* <ParachainsAbout /> */}
          {/* <ParachainsProjects /> */}
          <ParachainsPallets />
        </div>
      </section>
    </>
  );
};

export default ViewParachains;
