import React from "react";
import { Link } from "react-router-dom";

// Call CSS
import "../style/Projects.css";

// Call components
import TabPanelSec from "../Components/Others/TabPanelComps/TabPanelSec";
import Pagination from "../Components/Others/Pagination";
import SocialLinks from "../Components/Others/SocialLinks";
import TabPanelBody from "../Components/Others/TabPanelComps/TabPanelBody";
import AllProjects from "../Components/Projects/AllProjects";

const Projects = () => {
  let tabBodyData = [
    {
      Id: 1,
      value: <AllProjects />,
    },
    {
      Id: 2,
      value: <AllProjects />,
    },
    {
      Id: 3,
      value: <AllProjects />,
    },
    {
      Id: 4,
      value: <AllProjects />,
    },
  ];

  let tabNavData = [
    {
      Id: 1,
      value: "All",
    },
    {
      Id: 2,
      value: "Trending",
    },
    {
      Id: 3,
      value: "Polkadot",
    },
    {
      Id: 4,
      value: "Kusama",
    },
  ];

  return (
    <>
      <section className="container-fluid spc-y">
        <div className="container">
          {/* Top Part */}
          <div className="project-top-sec">
            <h3 className="hd-prim">Projects</h3>
            <div className="top-r-side">
              {/* Tab pannel Nav */}
              <TabPanelSec navData={tabNavData} />

              {/* Toggle Btn */}
              <button className="sqr-btn">
                <i class="fa-solid fa-filter"></i>
              </button>
            </div>
          </div>

          {/* Main Body Part */}
          <main className="project-main">
            {/* Side bar */}
            <aside>
              <button className="close-btn">
                <i class="fa-solid fa-xmark"></i>
              </button>

              <div className="sticky-div">
                <div className="filter-options">
                  <ul>
                    <li>
                      <Link to="#" className="nav-link active">
                        All
                      </Link>
                    </li>
                    <li>
                      <Link to="/nft" className="nav-link">
                        NFT
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="nav-link">
                        Utilities
                      </Link>
                    </li>
                    <li>
                      <Link to="/dapps" className="nav-link">
                        Dapps
                      </Link>
                    </li>
                    <li>
                      <Link to="/infrastructure" className="nav-link">
                        Infrastructure
                      </Link>

                      <ul className="sub-ul">
                        <li>
                          <Link to="/wallet" className="nav-link">
                            Wallets
                          </Link>
                        </li>
                        <li>
                          <Link to="/validators" className="nav-link">
                            Validators
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <SocialLinks />
            </aside>

            {/* Tab Pannel Body Section */}
            <TabPanelBody bodyData={tabBodyData} paddingVal="0 0 20px 0" />
          </main>

          {/* Pagination */}
          <Pagination classValue="pagination-sec justify-end" />
        </div>
      </section>
    </>
  );
};

export default Projects;
