import React from "react";

const Pagination = (proops) => {
  return (
    <>
      <div className={proops.classValue}>
        <button className="pg-np-btn">
          <i class="fa-solid fa-arrow-left"></i> Previous
        </button>

        <div className="pg-number-box">
          <button className="pg-no-btn active">1</button>
          <button className="pg-no-btn">2</button>
        </div>

        <button className="pg-np-btn">
          Next <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </>
  );
};

export default Pagination;
