import React from "react";

// Call Components
import SocialLinks from "../SocialLinks";
import { Link } from "react-router-dom";

// Call Images
import profile from "../../../Images/design.png";
import graphimg from "../../../Images/Graphimg.png";

const ProjectCard = () => {
  return (
    <>
      <div>
        <Link to="#">
          <div className="proj-card card-ele">
            <div className="d-flex justift-sp-b gap-15">
              {/* col 1 */}
              <div className="width-100 grow-1 d-flex gap-15">
                <img src={profile} alt="" className="img-circle shrink-0" />
                <div className="width-100 grow-1">
                  <h6 className="Category">Category</h6>
                  <h5 className="title hd-mini">Heading</h5>
                  <span className="git-box">
                    <i class="fa-brands fa-github"></i>
                    <i class="fa-solid fa-star"></i>
                    <span>48</span>
                  </span>
                </div>
              </div>

              {/* Col 2 */}
              <button className="btn share-btn shrink-0">
                <i class="fa-solid fa-share-nodes"></i>
              </button>
            </div>

            <div className="m-tag-sec">
              <span className="m-tag tag-pink">Library</span>
              <span className="m-tag tag-green">Polkadot</span>
              <span className="m-tag tag-purple">Kusama</span>
              <span className="m-tag tag-cream">Substrate</span>
            </div>
            <p>
              The introduction of cryptocurrencies in the recent past is one of
              the outcomes of innovation in the money market.
            </p>
            <div className="d-flex justify-spc-b gap-15">
              <SocialLinks />
              <img
                src={graphimg}
                alt="graph"
                style={{ width: "auto", height: "20px" }}
                className="shring-0"
              />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ProjectCard;
