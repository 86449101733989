import React from "react";

// Call Components
import Pagination from "../Others/Pagination";

// Call IMG
import filterLine from "../../Images/Filters-lines.svg";

const ParachainsPallets = () => {
  return (
    <>
      {/* PROJECTS & ARTICLE CARDS */}
      <div className="pallets-sec card-prim">
        {/* STATUS ROW */}
        <div className="status-s-row">
          <h6>Pallet Count 240</h6>
          {/* SEARCH COL */}
          <div className="search-container">
            {/* input field */}
            <div className="form-i-box">
              <i class="fa-solid fa-magnifying-glass"></i>
              <input
                type="search"
                name="search"
                className="inp-outline"
                placeholder="Search"
              />
            </div>

            {/* Subscribe btn */}
            <button className="dim-outline bt-x">
              <img src={filterLine} alt="filter icon" />
              Filters
            </button>
          </div>
        </div>

        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th>
                  Project{" "}
                  <button>
                    <i class="fa-solid fa-arrow-up"></i>
                  </button>
                </th>
                <th>Constants</th>
                <th>Errors</th>
                <th>Events</th>
                <th>Extrinsics</th>
                <th>Storage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Auctions</td>
                <td>
                  Ending Period | Lease Periods Per Slot Sample Length | Slot
                  Range Count
                </td>
                <td>
                  Ending Period | Lease Periods Per Slot Sample Length | Slot
                  Range Count
                </td>
                <td>
                  Ending Period | Lease Periods Per Slot Sample Length | Slot
                  Range Count
                </td>
                <td>Bid | Cancel Auction New Auction</td>
                <td>
                  AuctionCounter | AuctionInfo PalletVersion | ReservedAmounts |
                  Winning
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* PAGINATION  */}
      <Pagination classValue="pagination-sec justify-spc-b" />
    </>
  );
};

export default ParachainsPallets;
