import React from "react";

//Call Images
import PatternL from "../../Images/Line pattern-l.svg";
import PatternR from "../../Images/Line pattern-r.svg";

const HheroSec = () => {
  return (
    <>
      <section className="container-fluid h-hero-sec">
        <img src={PatternL} alt="pattern 1" className="img-L" />
        <img src={PatternR} alt="pattern 2" className="img-R" />
        <div className="container">
          <div className="home-box">
            <h1 className="main-HD">
              Curated for the <span>Contributors.</span>
            </h1>

            <p>
              Awesome dot is a company that curates the best resources, projects
              and learning in the polkadot ecosystem.
            </p>

            <div className="flex-btn-box">
              <button className="b-lg prim-outline btn-min-w">
                Search Projects
              </button>
              <button className="b-lg prim-btn btn-min-w">
                Explore all projects
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HheroSec;
