import React from "react";
import { NavLink } from "react-router-dom";

const NavLinks = () => {
  return (
    <>
      <ul className="nav-ul">
        <li>
          <NavLink className="nav-link" aria-current="page" to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to="/projects">
            Project
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to="/articles">
            Article
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to="/videos">
            Video
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to="/learn">
            Learn
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default NavLinks;
