// import ReactDOM from "react-dom/client";
// import "./style/App.css";
// import "./style/components.css";
// import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// Call CSS
import "./style/App.css";
import "./style/components.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// 👇 wrap App in Router
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
