import React from "react";

// Call Components
import { Link } from "react-router-dom";

// Call IMGs
import logo from "../../Images/logo.png";

const Brand = () => {
  return (
    <>
      <Link to="/">
        <img src={logo} alt="awesome" className="logo-img" />
      </Link>
    </>
  );
};

export default Brand;
