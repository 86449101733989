import React from 'react'
import ProjectCard from '../Components/Others/Cards/ProjectCard'

function Treanding() {
  return (
    <>
       <section className="container-fluid">
        <div className="container">
          {/* PROJECTS & ARTICLE CARDS */}
          <div className="grid-row-auto-A pb-20">
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />

          </div>

          {/* PAGINATION  */}
          {/* <Pagination classValue="pagination-sec justify-spc-b" /> */}
        </div>
      </section>
    </>
  )
}

export default Treanding