import React from "react";

// Pages
import ViewParachains from "./ViewParachains";
// import Parachains from "./Parachains";
// import VideoName from "./VideoName";
// import ViewArticles from "./ViewArticles";
// import ViewProject from "./ViewProject";

const Learn = () => {
  return (
    <>
      {/* <VideoName /> */}
      {/* <ViewArticles /> */}
      {/* <ViewProject /> */}
      {/* <Parachains /> */}
      <ViewParachains />
    </>
  );
};

export default Learn;
