import React from "react";

// Call Components
import SocialLinks from "./Others/SocialLinks";

// Call Css Files
import "../style/Footer.css";

// Call Images
import Logo from "../Images/logo.png";
import NavLinks from "./Navbar/NavLinks";

const Footer = () => {
  return (
    <>
      <footer className="container-fluid">
        <div className="container">
          {/* SEC A */}
          <div className="f-boxA">
            {/* SEC A-1 */}
            <div className="boxA1">
              {/* Logo */}
              <div>
                <img src={Logo} alt="Awesom Dot" className="foot-logo-img" />
              </div>
              {/* Footer Nav */}
              <NavLinks />
            </div>

            {/* SEC A-2 */}
            <div className="boxA2">
              <span className="span-box">
                Subscribe to our newsletter for latest updates
              </span>

              {/* SUBSCRIBE BOX */}
              <div className="subscribe-sec">
                {/* Sebscribe input field */}
                <div className="form-i-box">
                  <i class="fa-regular fa-envelope"></i>
                  <input
                    type="text"
                    name="subscribe"
                    className="inp-fill"
                    placeholder="Enter Your Email"
                  />
                </div>

                {/* Subscribe btn */}
                <button className="b-lg prim-btn">Subscribe</button>
              </div>

              <span className="span-box2 pe-20">
                <i class="fa-solid fa-check pe-10"></i> Daily updates
              </span>
              <span className="span-box2">
                <i class="fa-solid fa-check pe-10"></i> Weekly updates
              </span>
            </div>
          </div>

          {/* SEC B */}
          <div className="f-boxB">
            <p>© 2022 Awesome dot. All rights reserved.</p>
            <SocialLinks />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
