import React from "react";
import { Link } from "react-router-dom";

const SocialLinks = () => {
  return (
    <>
      <div className="social-box">
        <Link to="#" className="s-link">
          <i class="fa-brands fa-twitter"></i>
        </Link>

        <Link to="#" className="s-link">
          <i class="fa-brands fa-linkedin"></i>
        </Link>

        <Link to="#" className="s-link">
          <i class="fa-brands fa-facebook"></i>
        </Link>

        <Link to="#" className="s-link">
          <i class="fa-brands fa-github"></i>
        </Link>

        <Link to="#" className="s-link">
          <i class="fa-brands fa-dribbble"></i>
        </Link>
      </div>
    </>
  );
};

export default SocialLinks;
