import React from "react";
import { Link } from "react-router-dom";

// Call Images
import articleimg from "../../../Images/articleimg.png";
import UpRigArrow from "../../../Images/arrow-up-right.svg";
import Auther from "../../../Images/auther.jpg";

const ArticleCard = () => {
  return (
    <>
      <div>
        <Link to="">
          <div className="article-card card-ele">
            <img src={articleimg} alt="title" />
            <div className="article-top">
              <h5 className="title hd-mini">Heading</h5>
              <Link to="#">
                <img src={UpRigArrow} alt="view" />
              </Link>
            </div>

            <div className="m-tag-sec">
              <span className="m-tag tag-pink">Library</span>
              <span className="m-tag tag-green">Polkadot</span>
              <span className="m-tag tag-purple">Kusama</span>
              <span className="m-tag tag-cream">Substrate</span>
            </div>

            <p>
              The introduction of cryptocurrencies in the recent past is one of
              the outcomes of innovation in the money market.
            </p>

            <div className="author-box">
              <img src={Auther} alt="auther" />
              <div className="width-100">
                <h6 className="auther-name">Olivia Rhye</h6>
                <h6 className="post-date">24 Sept 2022</h6>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ArticleCard;
