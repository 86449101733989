import React from "react";

const ExploreBtn = () => {
  return (
    <>
      <button className="b-lg prim-btn btn-min-w">Explore all projects</button>
    </>
  );
};

export default ExploreBtn;
