import React from "react";

const Breadcrumb = (proops) => {
  return (
    <>
      <section className="container-fluid spc-y-2 pb-0">
        <div className="container Breadcrumb">
          <h3 className="hd-prim">{proops.title}</h3>
          <p>{proops.para}</p>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
