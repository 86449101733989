import React from "react";
import { Link } from "react-router-dom";

const TabPanelPrim = () => {
  let indicatorPosition = `calc(var(--dy-w) *2)`;
  //   /* Left value on active tabs */
  //   /* Tab 1 ==> left: 0; */
  //   /* Tab 2 ==> left: var(--dy-w); */
  //   /* Tab 3 ==> left: calc(var(--dy-w) *2); */

  return (
    <>
      {/* TABPANNEL PRIMARY*/}
      {/* Tab Nav*/}
      <div className="tabpannel-nav">
        {/* Tab Links UL */}
        <ul className="tab-ul">
          <li>
            <Link to="" className="tab-link active">
              Latest Projects
            </Link>
          </li>
          <li>
            <Link to="/articles" className="tab-link">
              Articles
            </Link>
          </li>
          <li>
            <Link to="/trending" className="tab-link">
              Trending
            </Link>
          </li>
        </ul>

        {/* Tab Indicators */}
        <div className="tab-indicator-box">
          <span style={{ left: indicatorPosition }}></span>
        </div>
      </div>
    </>
  );
};

export default TabPanelPrim;
