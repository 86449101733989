import React from "react";

//Call Components
import ProjectCard from "../Others/Cards/ProjectCard";

const AllProjects = () => {
  return (
    <>
      <div className="grid-row-auto-A w-100">
        <ProjectCard />
        <ProjectCard />
        {/* <ProjectCard /> */}
      </div>
    </>
  );
};

export default AllProjects;
