import React from "react";
import { Link } from "react-router-dom";

const TabPanelSec = (props) => {
  return (
    <>
      {/* Tab Nav Link Sec */}
      <div className="tabpannel-nav tab-style2">
        {/* Tab Links UL */}
        <ul className="tab-ul">
          {props.navData.map((e) => {
            return (
              <li>
                <Link to="" className={`tab-link`}>
                  {e.value}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TabPanelSec;
