import React from "react";

// Call CSS Files
import "../style/Home.css";

// Call Components
import HheroSec from "../Components/Home/HheroSec";
import TabPanelPrim from "../Components/Others/TabPanelComps/TabPanelPrim";
import ProjectCard from "../Components/Others/Cards/ProjectCard";
import ArticleCard from "../Components/Others/Cards/ArticleCard";

const Home = () => {
  return (
    <>
      {/* Sec 1 */}
      <HheroSec />

      {/* Sec 2 */}
      <section className="container-fluid">
        <div className="container spc-y">
          <TabPanelPrim />

          {/* Tab Pannel body content START*/}
          <div className="grid-row-auto-A" style={{ paddingTop: "40px" }}>
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            {/* <ArticleCard /> */}
          </div>
          {/* Tab Pannel body content END*/}
        </div>
      </section>
    </>
  );
};

export default Home;
