import React from "react";

//Call Images
import projImg from "../../../Images/proj-img.jpg";

//Call Components
import SocialLinks from "../SocialLinks";

const ProjectBreadcrumb = () => {
  return (
    <>
      <section className="container-fluid spc-y-2">
        <div className="container">
          <div className="projBreadcrumb">
            {/* BLOCK 1 */}
            <div className="Block1">
              <div className="B1-a">
                <img src={projImg} alt="project img" />
                <div>
                  <h1 className="hd-prim">Project Name</h1>
                  <p>Project categories</p>
                  <div className="tag-flex">
                    <button className="white-btn">Tag 1</button>
                  </div>
                </div>
              </div>
            </div>

            {/* BLOCK 2 */}
            <div className="Block2">
              <div className="mb-20">
                <button className="white-btn mr-10 bg-lite-gray">
                  <i class="fa-solid fa-eye mr-5"></i> Website
                </button>
                <button className="white-btn">
                  <i class="fa-solid fa-share-nodes mr-5"></i> Share
                </button>
              </div>
              <SocialLinks />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectBreadcrumb;
