import React from "react";

const TabPanelBody = (props) => {
  return (
    <>
      <div style={{ width: "100%", padding: `${props.paddingVal}` }}>
        {props.bodyData.map((e) => {
          return <div className={`tab-body-${e.Id}`}>{e.value}</div>;
        })}

        {/* Tab 3 */}
        {/* <div className="tab-body-3">hello 3</div> */}
      </div>
    </>
  );
};

export default TabPanelBody;
