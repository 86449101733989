import { Route, Routes } from "react-router-dom";

// Call Components
import Nav from "./Components/Navbar/Nav";
import Footer from "./Components/Footer";

// Call Pages
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import Articles from "./Pages/Articles";
import Videos from "./Pages/Videos";
import Learn from "./Pages/Learn";
import Treanding from "./Pages/Treanding";
import Nft from "./Components/Projects/Nft";

function App() {
  return (
    <>
      <header className="container-fluid">
        <Nav />
      </header>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/trending" element={<Treanding />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/learn" element={<Learn />} />

        {/* project card nft side  */}
        <Route path="/nft" element={<Nft />} />
        
      </Routes>
      <Footer />
    </>
  );
}

export default App;
