import React from 'react'
import ProjectCard from '../Others/Cards/ProjectCard'

function Nft() {
    
  return (
    <>
        <ProjectCard/>
    </>
  )
}

export default Nft