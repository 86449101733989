import React from "react";

// Call components
import Breadcrumb from "../Components/Others/Breadcrumbs/Breadcrumb";
import Pagination from "../Components/Others/Pagination";
import ProjectCard from "../Components/Others/Cards/ProjectCard";
import ArticleCard from "../Components/Others/Cards/ArticleCard";

const Articles = () => {
  return (
    <>
      {/* BREAD CRUMP */}
      <Breadcrumb
        title="Articles"
        para="The latest articles written by our top writters."
      />

      <section className="container-fluid">
        <div className="container">
          {/* PROJECTS & ARTICLE CARDS */}
          <div className="grid-row-auto-A pb-20">
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ArticleCard />
            <ArticleCard />
          </div>

          {/* PAGINATION  */}
          <Pagination classValue="pagination-sec justify-spc-b" />
        </div>
      </section>
    </>
  );
};

export default Articles;
